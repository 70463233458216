<template>
  <div
    class="p-3 items-center bg-white text-sm font-semibold rounded-md flex gap-2 text-gray-500"
    :class="{ 'cursor-pointer hover:bg-gray-100': !active }"
  >
    <div
      class="h-1.5 w-1.5 rounded-full"
      :class="{
        'bg-primary': active,
        'bg-gray-300': !active,
      }"
    ></div>
    <div class="flex-1 line-clamp-1" :class="{ 'text-gray-800': version.title && version.title.length > 0 }">
      {{ version.title && version.title.length > 0 ? version.title : 'Sem título' }}
    </div>
    <fw-tag v-if="isDraft" type="light-orange">Rascunho</fw-tag>
    <fw-tag v-else type="border-warning">v1.0</fw-tag>
  </div>
</template>
<script>
export default {
  name: 'RecordVersion',
  props: {
    version: {
      type: Object,
      default: function() {
        return {}
      },
    },
    active: {
      type: Boolean,
      default: false,
    },
    isDraft: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
