<template>
  <div class="w-full">
    <div class="flex-shrink-0 p-4 border-b border-gray-50 flex flex-col gap-4">
      <fw-heading size="h3">Versões do documento</fw-heading>
      <div class="flex gap-3">
        <fw-button class="flex-1" type="xlight" @click.native="$emit('snapshot')">
          {{ 'Criar versão  ' }}
        </fw-button>
        <fw-button class="flex-1" type="primary" @click.native="$emit('new-language')">
          {{ 'Aprovar' }}
        </fw-button>
      </div>
    </div>
    <div class="p-4 flex flex-col gap-2">
      <RecordVersion :active="true" :version="currentVersion" :is-draft="true" />
    </div>
  </div>
</template>
<script>
import RecordVersion from '../records/RecordVersion.vue'
export default {
  name: 'PanelSideVersions',
  components: {
    RecordVersion,
  },
  props: {
    versions: {
      type: Array,
      required: true,
    },
    currentVersion: {
      type: Object,
    },
  },
  methods: {
    createVersion() {
      this.$emit('create-version')
    },
    changeVersion(version) {
      this.$emit('change-version', version)
    },
  },
}
</script>
